import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OriginalUrlContext } from '../../utils/context';
import Paragraphs from '../../components/02_molecules/Paragraphs';
import HtmlHead from '../../components/01_atoms/HtmlHead';

import '../../styles/gifts/sass/theme.scss';

const GiftsLandingPage = ({ entity: page }) => {
  const originalUrl = useContext(OriginalUrlContext);

  const blocks = [...page.blocks];

  // Add "catalog" to "/corporate" page.
  if (originalUrl === '/corporate' || originalUrl.startsWith('/corporate?')) {
    blocks.push({ blockType: 'gifts_corporate_catalog' });
  }

  return (
    <div>
      <HtmlHead metatags={page.metaTags} pageTitle={page.title} />
      <Paragraphs blocks={blocks} layout={'centered'} />
    </div>
  );
};

GiftsLandingPage.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default GiftsLandingPage;
